import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faSignOutAlt} from "@fortawesome/pro-light-svg-icons";

const LogOutButton = withRouter(({ history, authService }) => (<Button onClick={authService.signout} className="btn btn-sm settings_page_buttons">
   <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", fontWeight: "400"}}>
      <div>
         {<FontAwesomeIcon color="#D7A157" style={{ fontSize: 17, marginLeft: 10, marginRight: 15}} icon={faSignOutAlt}/>}
         Log Out
      </div>
      {<FontAwesomeIcon color="#7C7A7A" style={{ fontSize: 17, textAlign: "right", marginRight: 10}} icon={faChevronRight}/>}
   </div>
   </Button>)
);

export default LogOutButton;
