import React, {useEffect, useState} from 'react';
import CustomerInfo from './customer_info';
import ToastNotifications from "../../../components/toasty-notifications/toasty-notifications";

const CustomerDetails = ({authService, changeTitleName}) => {
    const [customer, setCustomer] = useState({})

    useEffect(()=> {
        componentDidMount()
    },[])

    const componentDidMount = () => {
        authService.secureAjax({
            route: '/customer_details',
            onSuccess: response => {
                console.log(response)
                setCustomer(response.data)
            }
        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>
            <CustomerInfo
                changeTitleName={changeTitleName}
                activateForm={false}
                customer={customer}
                authService={authService}
                getCustomer={componentDidMount}
                onSuccess={()=>{
                    ToastNotifications.successToast('Details updated successfully.',5000)
                }}/>
        </div>
    )

}

export default CustomerDetails;
