import React, {Component, PropTypes} from 'react'
import Image from 'react-bootstrap/Image';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios/index";
import * as Sentry from "@sentry/react";
import ActivatedDeviceResponse from "../activate_device_page/activated_device_response"
import './activate_device_page.css';
import {HeaderAndDevicesList} from "./header-and-devices-list";
import {SpinningButton, SubmitButton} from "../submit-button";
import {faCheck, faPlus} from "@fortawesome/pro-light-svg-icons";
import {faQrcode} from "@fortawesome/pro-solid-svg-icons";
import QrScanner from "./qr_reader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class ActivateDevicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'initial',
      machine: this.generateState('initial'),
      loading: false,
      qrScannerVisible: false,
      addByScanningOption: true,
      scannedIMEI: '',
    };
  }

  setScannedIMEI = (imei) => {
    this.setState({ scannedIMEI: imei })
}


  generateState = (stateName) => {
    switch (stateName) {
      case 'initial':
        return {
          deviceImei: '',
          deviceImeiStatus: null,
          isImeiInputVisible:  this.state ? !this.state.addByScanningOption : false,
          isActivateButtonEnabled: false,
          isActivateButtonVisible: this.state ? !this.state.addByScanningOption : false,
          isAnotherBikeButtonVisible: false,
          isFinishButtonVisible: false,
          isBikeAddedBeforeFinish: false,
        };
      case 'firstFailedDevice':
        return {
          deviceImei: '',
          deviceImeiStatus: false,
          isImeiInputVisible: !this.state.addByScanningOption,
          isActivateButtonEnabled: false,
          isActivateButtonVisible: this.state ? !this.state.addByScanningOption : false,
          isAnotherBikeButtonVisible: false,
          isFinishButtonVisible: false,
          isBikeAddedBeforeFinish: false,
        };
      case 'successDevice':
        return {
          deviceImei: '',
          deviceImeiStatus: true,
          isImeiInputVisible: false,
          isActivateButtonEnabled: false,
          isActivateButtonVisible: false,
          isAnotherBikeButtonVisible: !this.state.addByScanningOption,
          isFinishButtonVisible: true,
          isBikeAddedBeforeFinish: true,
        };
      case 'addAnotherDevice':
        return {
          deviceImei: '',
          deviceImeiStatus: null,
          isImeiInputVisible: !this.state.addByScanningOption,
          isActivateButtonEnabled: false,
          isActivateButtonVisible: this.state ? !this.state.addByScanningOption : false,
          isAnotherBikeButtonVisible: false,
          isFinishButtonVisible: true,
          isBikeAddedBeforeFinish: true,
        };
      case 'addAnotherDeviceFail':
        return {
          deviceImei: '',
          deviceImeiStatus: false,
          isImeiInputVisible: !this.state.addByScanningOption,
          isActivateButtonEnabled: false,
          isActivateButtonVisible: this.state ? !this.state.addByScanningOption : false,
          isAnotherBikeButtonVisible: false,
          isFinishButtonVisible: true,
          isBikeAddedBeforeFinish: true,
        };
    }
  };

   setLoadingValue = (value) => {
    this.setState({
      loading: value
    })
  }


  goToState = (stateName, stateParam) => {
    this.setState({
      name: stateName,
      machine: this.generateState(stateName, stateParam)
    })
  };

// this method also set imei value to machine state
  isImeiValid = (e, scannedIMEI = '') => {
    let imei;
    if(e) {
      imei = e.target.value;
    } else {
      imei = scannedIMEI;
    }

    if (imei.length <= 15 && /^[0-9]*$/.test(imei)) {
      this.setState(prevState => ({
        machine: {
          ...prevState.machine,
          deviceImei: imei,
        }
      }));
      if (imei.length === 15) {
        this.setState(prevState => ({
          machine: {
            ...prevState.machine,
            isActivateButtonEnabled: true,
          }
        }));
      } else {
        this.setState(prevState => ({
          machine: {
            ...prevState.machine,
            isActivateButtonEnabled: false,
          }
        }));
      }
    }
  };


  activateDevices = () => {
    this.setLoadingValue(true)
    let self = this;
    const imei = this.state.machine.deviceImei;
    const route = process.env.REACT_APP_API_URL + '/lookup_device';
    return axios.post(route, {
      imei: imei,
    })
      .then(response => {
        self.setLoadingValue(false)
        if (response.data.status) {
          this.goToState('successDevice');
          this.props.addDevice(imei);
        } else if (!response.data.status && !this.state.machine.isBikeAddedBeforeFinish) {
          this.goToState('firstFailedDevice')
        } else if (!response.data.status && this.state.machine.isBikeAddedBeforeFinish) {
          this.goToState('addAnotherDeviceFail')
        }
      })
      .catch(function (error) {
        self.setLoadingValue(false)
        Sentry.captureException(new Error(error));
      })
  };

  hideScanner = () => {
    this.setState({qrScannerVisible: false})
}


  render() {
    const addedDevices = this.props.addedDevices;
    const {
      deviceImei,
      deviceImeiStatus,
      isImeiInputVisible,
      isActivateButtonEnabled,
      isActivateButtonVisible,
      isAnotherBikeButtonVisible,
      isFinishButtonVisible
    } = this.state.machine;

    return (
      <div className="sign-up-form-container">
        <HeaderAndDevicesList addedDevices={addedDevices}/>
        {this.state.qrScannerVisible && (<QrScanner hideScanner={this.hideScanner} passIMEI={this.isImeiValid} activateDevice={this.activateDevices} setScannedIMEI={this.setScannedIMEI} /> )}

        <Form className="middle-section-container" style={{paddingBottom: !this.state.qrScannerVisible ? 150 : 0 }} onSubmit={ e => {
          e.preventDefault();
        } }>
          {deviceImeiStatus !== null
            ? <ActivatedDeviceResponse deviceImeiStatus={deviceImeiStatus}/>
            : ''}
          {isImeiInputVisible && (
              <Form.Group style={{display: isImeiInputVisible ? "block" : "none", marginBottom: 0}} className="input-container">
                <Form.Label className={deviceImei && "filled"}>Enter IMEI</Form.Label>
                <Form.Control as="input"
                              style={{ borderRadius: 10, paddingBottom: 0}}
                              name={"IMEI"}
                              value={deviceImei || this.state.scannedIMEI}
                              onChange={this.isImeiValid}
                              className="form-input"
                />
              </Form.Group>
          )}
          {this.state.addByScanningOption && !this.state.qrScannerVisible && (
              <div>
                <Button className="gold-button-variant"
                        style={{display: "block"}}
                        onClick={() => this.setState({qrScannerVisible: true})}>
                  <FontAwesomeIcon icon={faQrcode} style={{marginRight: 8}}/>
                  {isFinishButtonVisible ? 'Link Additional device' : 'Scan Device Barcode' }
                </Button>
                <div style={{marginTop: '10px', cursor: "pointer"}} onClick={() => {
                  this.setState({
                    addByScanningOption: false
                  }, () => {
                    this.goToState('initial')
                  });}
                }>
                  <p style={{color: '#D7A157', fontWeight: "bolder", fontSize: '15px', textDecoration: "underline", textDecorationColor: "#D7A157"}}>
                    Or enter manually
                  </p>
                </div>
              </div>
          )}

{/*          <Form.Group style={{display: isImeiInputVisible ? "block" : "none", marginBottom: 0}} className="input-container">
            <Form.Label className={deviceImei && "filled"}>Device IMEI (15 numeric digits)</Form.Label>
            <Form.Control as="input"
                          style={{borderRadius: 10, paddingBottom: 0}}
                          name={"IMEI"}
                          value={deviceImei}
                          onChange={this.isImeiValid}
                          className="form-input"
            />
          </Form.Group>*/}

          {isActivateButtonVisible && (
              <>
                <SpinningButton loading={this.state.loading} title="Add Device" disabled={!isActivateButtonEnabled}
                                action={this.activateDevices} icon={faPlus} buttonVariant="gold-button-variant"/>
                <div style={{marginTop: '10px', cursor: "pointer"}} onClick={() => {
                  this.setState({
                    addByScanningOption: true,
                    qrScannerVisible: true,
                  }, () => {
                    this.goToState('initial')
                  });
                }
                }>
                  <p className="alternative-option-text">
                    Or scan device barcode
                  </p>
                </div>
              </>
          )}
          <SpinningButton title="Add Another device" visible={isAnotherBikeButtonVisible} disabled={!isAnotherBikeButtonVisible} icon={faPlus} action={() => this.goToState('addAnotherDevice')} buttonVariant="black-button-variant" />
          { isFinishButtonVisible && (<SpinningButton loading={this.state.loading} title="Continue" action={() => this.props.submitDevices(this.setLoadingValue)} icon={faCheck} />
          )}
        </Form>
      </div>
    )
  }
}

export default ActivateDevicePage;
