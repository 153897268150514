import React from 'react';
import { Formik } from 'formik';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ToastNotifications from "../../../../components/toasty-notifications/toasty-notifications";
import {verificationCodeValidationSchema} from "../../../../services/validation_schema";

export const UpdateEmailVerification = ({ setCustomerData, user, authService }) => {

    const resendVerificationCodeRequest = () => {
        authService.secureAjax({
            route: '/resend_verification_code',
            method: 'post',
            onSuccess() {
                ToastNotifications.successToast('Email with verification code has been sent', 3000);
            },
            onFailure() {
                ToastNotifications.errorToast('', 'Something went wrong', 3000);
            }
        })
    };

    const cancelPendingEmailRequest = () => {
        authService.secureAjax({
            route: '/cancel_pending_email',
            method: 'post',
            onSuccess() {
                setCustomerData();
            },
            onFailure() {
                ToastNotifications.errorToast('', 'Something went wrong', 3000);
            }
        })
    };

    const updateEmailRequest = inputData => {
        const { verificationCode } = inputData;
        authService.secureAjax({
            params: {verificationCode },
            route: '/update_email',
            method: 'post',
            onSuccess() {
                setCustomerData()
            },
            onFailure() {
                ToastNotifications.errorToast('', 'Something went wrong while updating email', 3000);
            }
        })
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    verificationCode: '',
                }}
                onSubmit={updateEmailRequest}
                validationSchema={verificationCodeValidationSchema}
            >
                {({ handleChange, values, handleSubmit, errors, isValid }) => (
                    <Form onSubmit={handleSubmit} className="container form-container">
                        <Form.Group className="input-container">
                            <Form.Label className={values.verificationCode && "filled"}>Verification Code</Form.Label>
                            <Form.Control type="text"
                                          className="form-input"
                                          value={values.verificationCode}
                                          name="verificationCode"
                                          onChange={handleChange}
                            />
                        </Form.Group>

                        <Button className="gold-button-variant" type="submit" disabled={!isValid}>
                            Confirm Code
                        </Button>
                    </Form>
                )}
            </Formik>
            <div style={{ padding: 10, alignItems: 'flex-start', justifyContent: 'center' }}>
                <p style={{ color: '#fafafa', marginTop: 15, left: 10 }}>
                    A code to authenticate your change of profile email address was sent to{' '}
                    {user.pendingEmail}
                </p>
                <p style={{ color: '#fafafa', marginTop: 15, left: 10 }}>
                    Didn’t mean to change your email? <span onClick={() => cancelPendingEmailRequest()} style={{ left: 5, top: 3, color: '#fafafa', fontWeight: '800' }}>Cancel</span>
                </p>
                <p style={{ color: '#fafafa', marginTop: 15, left: 10 }}>
                    Didn’t receive a code? <span onClick={() => resendVerificationCodeRequest()} style={{ left: 5, top: 3, color: '#fafafa', fontWeight: '800' }}>Resend</span>
                </p>
            </div>
        </>
    );
};
