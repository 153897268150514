import * as Yup from 'yup';
const PHONE_REGEX = /^(([+]\d{2}[ ]?)|0)\d{10}$/;

export const activateAccountValidationSchema = Yup.object().shape({
    activationToken: Yup.string()
        .label('activationToken')
        .required('Activation token must be present')
})


export const signUpValidationSchema = Yup.object().shape({
    email: Yup.string()
        .label('Email')
        .trim()
        .email('Enter a valid email')
        .required('Email must be present'),
    phoneNumber: Yup.string()
        .label('phoneNumber')
        .required('Phone number must be present')
        .transform(value => {
            return value.replace(/\s*/g, '');
        })
        .matches(PHONE_REGEX, 'Please enter a valid number'),
    name: Yup.string().label('name').required('Name must be present'),
    password: Yup.string()
        .label('password')
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .max(12, 'Password is too long - should be 12 chars maximum'),
})

export const customerValidationSchema = Yup.object().shape({
    name: Yup.string().label('name').required('Name must be present'),
    phoneNumber: Yup.string()
        .label('phoneNumber')
        .required('Phone number must be present')
        .transform(value => {
            return value.replace(/\s*/g, '');
        })
        .matches(PHONE_REGEX, 'Please enter a valid number'),
});

export const deleteAccountValidation = Yup.object().shape({
    email: Yup.string()
        .label('Email')
        .trim()
        .email('Enter a valid email')
        .required('Email must be present'),
    password: Yup.string().required('Password must be present'),
    deleteWord: Yup.string().label('deleteWord').required('DELETE word must be present'),
});

export const emailValidationSchema = Yup.object().shape({
    email: Yup.string()
        .label('Email')
        .trim()
        .email('Enter a valid email')
        .required('Email must be present'),
    currentPassword: Yup.string().label('Password').required('Password must be present to proceed'),
});

export const verificationCodeValidationSchema = Yup.object().shape({
    verificationCode: Yup.string().label('VerificationCode').trim().required('Code must be present'),
});

export const emergencyContactValidationSchema = Yup.object().shape({
    name: Yup.string().label('name').required('Name must be present'),
    phoneNumber: Yup.string()
        .label('phoneNumber')
        .required('Phone number must be present')
        .transform(value => {
            return value.replace(/\s*/g, '');
        })
        .matches(PHONE_REGEX, 'Please enter a valid number'),
    relationship: Yup.string()
        .label('relationship')
        .required('Relationship must be present')
        .nullable(),
});
