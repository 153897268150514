import React, {useState} from 'react';
import Image from 'react-bootstrap/Image';
import ShowSubmitted from './show_submitted';
import CreateAccountFlow from './create_account_flow';
import "./signup.css"
import {Formik} from "formik";
import {activateAccountValidationSchema} from "../../services/validation_schema";
import Form from "react-bootstrap/Form";
import {activateAccount, resendActivationToken} from "./sign-up-utils";
import { SubmitButton }  from "./submit-button";
import {Link} from "react-router-dom";
import Notification, {notify} from "react-notify-toast";
import {faTimesSquare} from "@fortawesome/pro-light-svg-icons/faTimesSquare";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ValidateActivationToken = ({ userEmail }) => {
    const [loading, setLoading] = useState(false)
    const setLoadingValue = (value) => {
        setLoading(value)
    }
    function DisplayErrors({error, field}){
        return error ? (<div style={{color: "#fa2a43", paddingBottom: "3px", textAlign: 'center', fontSize: 12}}>{error}<br /> </div>) : null
    }
    const handleSubmit = (values) => {
        console.log(values)
        activateAccount(values, setLoadingValue)
    }

    const resendToken = () => resendActivationToken(userEmail)

    return (
            <div className="signup">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", maxWidth: 470}}>
                        <Notification />
                        <div style={{paddingTop: 10, paddingBottom: 35}} className="signup_container container-form">
                            <div className="login-form-logo-holder">
                                <Image src={"/logo_transparent.png"} style={{height: '50%', width: '75%'}} className="mx-auto"/>
                            </div>
                            {userEmail && (
                                <div>
                                    <p>Activation code has been sent to</p>
                                    <p style={{fontSize: 16}}><b>{userEmail}</b></p>
                                </div>
                            )}
                            <p style={{marginBottom: 25}}>Enter code below</p>
                            <div className="signup_body">
                                <Formik enableReinitialize
                                        validationSchema={activateAccountValidationSchema}
                                        initialValues={{activationToken: ''}}
                                        onSubmit={ values => {
                                            handleSubmit(values)
                                        }}>
                                    {({values, handleChange, handleSubmit, errors, touched}) => (
                                        <Form onSubmit={handleSubmit} className="container form-container">
                                            <Form.Group controlId="validateActivationToken.activationToken" className="input-container">
                                                <Form.Control
                                                    type="text"
                                                    className="form-input"
                                                    value={values.activationToken}
                                                    onChange={handleChange}
                                                    name="activationToken"
                                                    pattern="[0-9]*"
                                                />
                                                <Form.Label className={values.activationToken && "filled"}>Activation Code</Form.Label>
                                                <DisplayErrors error={touched.activationToken && errors.activationToken} field="activationToken"/>
                                            </Form.Group>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                                <SubmitButton loading={loading} title="Confirm"/>
                                                <div style={{marginLeft: 20}} className="btn basic-button-variant">
                                                    <Link style={{textDecoration: "none", color: "#ced4da", display: "block"}} to="/login">
                                                        Cancel
                                                    </Link>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            {userEmail && (
                                <div style={{marginTop: 35, color: '#fafafa'}}>
                                    <p onClick={() => resendToken()} style={{cursor: 'pointer'}}>Didn't receive a code? <span className="verifyCodeText">Resend </span></p>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </div>
    )
};





