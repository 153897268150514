import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {faLock} from "@fortawesome/pro-light-svg-icons";
import React from "react";

export const SubmitButton = ({loading, title}) =>
        <Button type="submit" className="gold-button-variant">
            {loading ? <FontAwesomeIcon style={{position: 'relative', left: 9, fontSize: 15}} icon={faSpinner} spin/> :
                <FontAwesomeIcon style={{position: 'relative', right: 9, fontSize: 15}} icon={faLock}/>}
            {!loading ? title : ''}
        </Button>


export const SpinningButton = ({loading, title, visible = true, disabled = false, action, icon, buttonVariant = 'gold-button-variant'}) => {
        return(
        <Button className={buttonVariant}
                style={{display: visible ? "block" : "none"}}
                disabled={disabled}
                onClick={(e) => action(e)}>
                {loading ?
                    <FontAwesomeIcon style={{position: 'relative', left: 9, fontSize: 15}} icon={faSpinner} spin/> :
                    <FontAwesomeIcon style={{position: 'relative', right: 9, fontSize: 15}} icon={icon ? icon : faLock}/>}
                {!loading ? title : ''}
        </Button>
        )
}