import React, {useState} from 'react';

import ActivateDevicePage from './activate_device_page/activate_device_page';
import SubmitDevicesAndEmail from './submit_devices_and_email';

const CreateAccountForm = ({setSubmittedSuccessfully}) => {

  const [allowSubmission, setAllowSubmission] = useState(false);
  const [addedDevices, setAddedDevices] = useState([]);

  const addDevice = imei => {
    if (addedDevices.indexOf(imei) === -1){
      setAddedDevices([...addedDevices, imei]);
    }
  };

  return (
    <div>

      { allowSubmission ? <SubmitDevicesAndEmail addedDevices={addedDevices}
                                                 setSubmittedSuccessfully={setSubmittedSuccessfully}/> :
                          <ActivateDevicePage addDevice={addDevice}
                                              addedDevices={addedDevices}
                                              allowEmailSubmission={()=>{setAllowSubmission(true)}}/> }
    </div>
  );
};

export default CreateAccountForm;