import React, {useEffect, useState} from 'react';
import EmergencyContactForm from "./emergency_contact_form";

const EmergencyContact = ({authService}) => {
    const emergencyContactTemplate = {
        id: -1,
        name: '',
        phoneNumber: '',
        enabled: false,
        relationship: 'Relationship to Driver'
    }
    const [emergencyContact, setEmergencyContact] = useState(emergencyContactTemplate)

   const getEmergencyContactDetails = () => {
        authService.secureAjax({
            route: '/emergency_contact_details',
            method: 'get',
            onSuccess(response) {
                setEmergencyContact(response.data)
            },
            onFailure(error) {
                if (error.response.status === 404) {
                    setEmergencyContact(emergencyContactTemplate)
                }
            }
        })
    }

    // const emergencyContactDataSetter = () => {
    //     setEmergencyContactData
    // }

    useEffect(() => {
        getEmergencyContactDetails();
    }, [])


    return (
        <>
            {/*{Object.keys(emergencyContact).length < 1 ? (*/}
            {/*    <div>Loading</div>*/}
            {/*) : (*/}
                <EmergencyContactForm
                    authService={authService}
                    setEmergencyContactData={getEmergencyContactDetails}
                    contactDetailsCreated={emergencyContact.id > 0}
                    emergencyContact={emergencyContact}
                />
            {/*) }*/}

        </>
    )
}
export default EmergencyContact;
