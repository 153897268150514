import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons';


class ActivatedDeviceResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {deviceImeiStatus} = this.props;

    let responseIcon, responseStatus, responseText;

    if (deviceImeiStatus === true) {
      responseText = "IMEI validated and pending activation. "
    } else if (deviceImeiStatus === false) {
      responseIcon = faTimesCircle;
      responseStatus = "error-icon";
      responseText = "Device Not Recognised";
    }
    return (
      <div className="response-container">
        {responseIcon && (
            <FontAwesomeIcon id={responseStatus} icon={responseIcon} size="sm"/>
        )}
        <div style={{fontSize: 12, marginBottom: 0, color: deviceImeiStatus ? '#48ce78' : '#dc3545'}}>{responseText}</div>
      </div>
    )
  }
}

export default ActivatedDeviceResponse;