import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import ActivateDevicePage from "../signup/activate_device_page/activate_device_page";
import Button from "react-bootstrap/Button";
import {activateAccount, addDevices} from "../signup/sign-up-utils";
import { RecipientInfo } from "../signup/recipient_info";
import LogOutButton from "./logout_button";
import SimpleLogOutButton from "./simple_logout_button";

export const MoveDevicesToAccount = ({authService, email, confirmToken, recipient, next, setFormMessage, billingUser}) => {
    const [addedDevices, setAddedDevices] = useState([]);

    const addDevice = imei => {
        if (addedDevices.indexOf(imei) === -1) {
            setAddedDevices([...addedDevices, imei]);
        }
    };

    const submitDevices = (setLoadingValue) => {
        const values = {email, activationToken: confirmToken, addedDevices }
        console.log(values)
        addDevices(values, setLoadingValue)
    }

    const goToTheMotohaloStore = () => {
        window.location = 'https://www.motohalo.co.uk/'
    }

    return (
        <div className="add-devices-container">
            <div className="add-devices-form-holder" style={{maxWidth: 470, flex: 1}}>
                {addedDevices.length < 1 && (
                    <div className="setupInfo" style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        We now need to link your Motohalo device to your profile.
                        Please scan the QR code printed on the device
                        or manually enter the IMEI
                    </div>
                )}
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
                    <ActivateDevicePage addDevice={addDevice}
                                        addedDevices={addedDevices}
                                        submitDevices={submitDevices}
                    />
                </div>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingBottom: '5px'}}>

                    {/*//todo Uncomment later*/}
                    {/*{!billingUser && (*/}
                    {/*    <div  className="visit-store-button">*/}
                    {/*        <Button className="gold-button-variant"*/}
                    {/*                style={{display: "block", fontWeight: 600}}*/}
                    {/*                onClick={() => next()}>*/}
                    {/*            Add later*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*)}*/}


                    <div  className="visit-store-button">
                        {addedDevices.length < 1 && (
                            <Button className="black-button-variant"
                                    style={{display: "block", fontWeight: 600}}
                                    onClick={() => goToTheMotohaloStore()}>
                                Visit Motohalo Store
                            </Button>
                        )}
                    </div>
                    <div  className="visit-store-button">
                        <SimpleLogOutButton title={"Log Out"} authService={authService} />
                    </div>
                </div>
            </div>
        </div>
    )
}
