import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import { Provider } from 'react-keep-alive';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";


/*<Provider include="Journeys">*/
/*<App />*/
/*</Provider>*/
//TODO Move DSN to env file
Sentry.init({
    dsn: "https://bcd25bdec5ef4067a689013cc3ea2a76@o292104.ingest.sentry.io/1550136",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.25,
    environment: "production",
    debug: false,
});


ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

