import React, { useState, useEffect } from 'react';
import {Formik} from 'formik';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PaymentOption from './payment_option'
import './checkout_form.css'
// import {CardElement} from '@stripe/react-stripe-js';
import ToastNotifications from '../../components/toasty-notifications/toasty-notifications';

import {
  useStripe,
  useElements,
  CardElement
} from "@stripe/react-stripe-js";
import Spinner from "../../components/spinner/spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import {SpinningButton, SubmitButton} from "../signup/submit-button";


// TODO
// Handle errors from our API or stripe being down
// once the checkout process keeps track of where you're up to.
const CheckoutForm = ({next, authService, pendingActivationDevices}) => {
  const [loading, setLoading] = useState(false);
  const [pricingData, setPricingData] = useState({
    numberOfBikes: 1,
    monthlyPrice: 999,
    annualPrice: 9900,
    bikes: [],
  });

  useEffect(()=> {
    console.log('getting data')
    authService.secureAjax({
      route: '/pricing_info',
      onSuccess(response) {
        console.log('setting pricing data')
        setPricingData(response.data);
        // getDevices(pricingData.bikes)
      },
    })
  },[]);

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleCardDetailsSubmission = async () => {
    console.log('submitting')
    setLoading(true)
    authService.secureAjax({
      route: '/checkout_form',
      params: {
        quantity: pricingData.numberOfBikes
      },
      method: 'get',
      onSuccess(response) {
        // activateAccount()
        window.open(response.data.url, '_self')
      },
      onFailure(error) {
        let errorMsg;
        setLoading(false)
        console.log('error happened here on failure')
        if (error.response && error.response.data) {
          errorMsg = error.response.data.errorMsg
        }
        if (errorMsg) {
          setError(errorMsg); //error from validating card
        } else {
          ToastNotifications.defaultErrorToast(error, 1000); // something unexpected
        }
        console.log('erroir happened int he last block')
        console.log(error);
      }
    })
  }

  if(loading){
    return <Spinner message="Setting up your new Account"/>
  }
  return (<div style={{padding: 0, height: '55%'}} className="billing-middle-section">
    <div style={{height: '90%'}} className="added-devices-and-button-container">
      <h5>Devices</h5>
      <hr className="separator"/>
      <div className="info-container">
        {pricingData.bikes.map((bike, index) => (<div  key={index + 1} className={"info-row"}>
          <div className="fa-info-icon"><FontAwesomeIcon icon={faCheck}/></div>
          <div className="info-text">{bike.unitId}</div>
        </div>))}
      </div>
      <p style={{color: '#48ce78'}}>Account & devices ready for activation</p>
    </div>
    <div className="setup-billing-button">
      <SpinningButton loading={loading} title="Select a plan to get started" action={() => handleCardDetailsSubmission()}/>
    </div>
  </div>);
};

export default CheckoutForm;

