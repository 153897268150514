import React, {useEffect, useState} from 'react'
import Button from "react-bootstrap/Button";
import {
    completeActivationProcess
} from "../signup/sign-up-utils";
import Spinner from "../../components/spinner/spinner";
import './smart-banners.css';
import '../signup/signup.css'
import {isAndroid, isMobile} from "react-device-detect";
import SimpleLogOutButton from "./simple_logout_button";
import SmartBanner from "react-smartbanner";

export const STORE_TEXT = 'Keep eyes on your Motorcycle'

export const SuccessScreen = ({authService}) => {
    // const [submitDevices, setSubmitDevices] = useState(false);
    const [loading, setLoading] = useState(false);
    const mobileApp = localStorage.mobile_app;

    const endLoading = () => setLoading(false)

    useEffect(() => {
        completeActivationProcess(endLoading, authService)
    }, [])

    const getIOSApp = () => {
        window.location = 'https://apps.apple.com/gb/app/moto-halo/id1505515077'
    }

    const getAndroidApp = () => {
        window.location = 'https://play.google.com/store/apps/details?id=com.bikeeyes'
    }

    return (
        <div className="container-form">
            {loading && <Spinner message="Activating your account"/> }
            <header className="success-screen-heading">
                <h4>Congratulations! Your Motohalo account is now activated 🥳</h4>
            </header>
            {/*{!loading && <SmartBanner title={'MotoHalo'} daysHidden={1} daysReminder={1} price={{ios: '', android: ''}} storeText={{ios: STORE_TEXT, android: STORE_TEXT}}/> }*/}

            <div>
                <>
                    <div>
                        <p className="success-screen-text">Your newly purchased tracking device (s) have been activated
                            and transferred to your new account. They are ready to be installed.</p>
                    </div>
                    <div>
                        <p className="success-screen-text">To complete the setup process, please return to the Motohalo
                            mobile app and login to access your new account 👉📱</p>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <div className='line'/>
                        <span style={{flex: 2, textAlign: 'center'}}>alternatively</span>
                        <div className='line'></div>
                    </div>

                </>
            </div>
            <div>
                {!isMobile ? (
                    <div>
                        <Button className="black-button-variant success-button "
                                onClick={() => getIOSApp()}>
                            Get Motohalo for iPhone 📱
                        </Button>
                        <Button className="black-button-variant  success-button"
                                onClick={() => getAndroidApp()}
                                style={{marginBottom: '10px'}}>
                            Get Motohalo for Android 📱
                        </Button>
                    </div>
                ) : (
                    isAndroid ? (
                        <Button className="black-button-variant success-button bottom-margin"
                                onClick={() => getAndroidApp()}
                        >
                            GET MOTOHALO APP 📱
                        </Button>
                    ) : (
                        <Button className="black-button-variant success-button bottom-margin"
                                onClick={() => getIOSApp()}>
                            GET MOTOHALO APP 📱
                        </Button>
                    )
                )
                }
            </div>
        </div>
    )
}