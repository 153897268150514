import React, {useState} from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './checkout_form';
import {RecipientInfo} from "../signup/recipient_info";
import {addDevices} from "../signup/sign-up-utils";
import ActivateDevicePage from "../signup/activate_device_page/activate_device_page";
import {SuccessScreen} from "./success_screen";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


const SetupBilling = ({next, authService, email, recipient, onSuccess, pendingActivationDevices}) => {
    const  submitDevices = (email, confirmToken, addedDevices) => {
        const values = {email, activationToken: confirmToken, addedDevices }
        addDevices(values)
    }

  return (
      <>
          <div style={{height: '100%'}} className="billing-page-container">
              <RecipientInfo recipient={recipient} email={email} headingText="Profile"/>
              <Elements stripe={stripePromise}>
                  <CheckoutForm next={next} authService={authService}
                                pendingActivationDevices={pendingActivationDevices} activateAccount={onSuccess}/>
              </Elements>
          </div>
      </>
  )
};

export default SetupBilling;