import React, {useState} from 'react';
import "./account_settings.css";
import {Formik} from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToastNotifications from "../../../components/toasty-notifications/toasty-notifications";
import * as Sentry from "@sentry/react";
import {customerValidationSchema} from "../../../services/validation_schema";
import ChangePassword from "./change_password";
import ChangeEmail from "./change_email/change_email";
import {faPencil} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CustomerInfo = ({customer, authService, onSuccess, activateForm, getCustomer, changeTitleName}) => {
    const [settingsPage, setSettingsPage] = useState("Profile")
    const { accountHolder } = customer
    const changeSettingsPage = (value) => {
        setSettingsPage(value)
        changeTitleName(value)
    }

  return (
   <>
       {settingsPage === "Change E-Mail Address" && (
           <ChangeEmail authService={authService} customer={customer} getCustomer={getCustomer}/>
       )}
       {settingsPage === "Change Password" && (
           <ChangePassword />
       )}
       {settingsPage === "Profile" && (
           <Formik initialValues={{
               ...customer,
               name: accountHolder && accountHolder.name,
               phoneNumber: accountHolder && accountHolder.phoneNumber || '',
           }}
                   enableReinitialize
                   validationSchema={customerValidationSchema}
                   onSubmit={ values => {
                       values.updateEmergencyDetails = true;
                       console.log(JSON.stringify((values)))
                       updateCustomerDetails(values, authService, onSuccess)
                   }}
           >
               {({values, handleChange, handleSubmit,handleBlur,errors, touched, isValid}) => {
                   return (
                       (
                           <Form  onSubmit={handleSubmit} className="container form-container">
                               <Form.Group controlId="customerForm.name" className="input-container">
                                   <Form.Label className={values.name && "filled"}>Profile Name</Form.Label>
                                   <Form.Control
                                       className="form-input"
                                       type="text"
                                       placeholder=""
                                       onBlur={handleBlur('name')}
                                       value={values.name}
                                       onChange={ handleChange }
                                       name="name"
                                   />
                                   <DisplayErrors error={touched.name && errors.name} />
                               </Form.Group>

                               <div className="change-profile_row">
                                   <Form.Group style={{flex: 1}}  controlId="customerForm.name" className="input-container">
                                       <Form.Label className="filled">Email</Form.Label>
                                       <Form.Control
                                           className="form-input"
                                           type="text"
                                           value={values.email}
                                           name="email"
                                           readOnly={true}
                                       />
                                   </Form.Group>
                                   <Button
                                       className="change-profile_edit-button"
                                       onClick={()=>changeSettingsPage("Change E-Mail Address")}
                                       type="submit" >
                                       <FontAwesomeIcon
                                           style={{marginRight: 10}}
                                           icon={faPencil}/>
                                       Edit
                                   </Button>
                               </div>

                               <div className="change-profile_row">
                                   <Form.Group style={{flex: 1}} controlId="customerForm.name" className="input-container">
                                       <Form.Label className="filled">Password</Form.Label>
                                       <Form.Control
                                           className="form-input"
                                           type="password"
                                           value="********"
                                           name="password"
                                           readOnly={true}
                                       />
                                   </Form.Group>
                                   <Button
                                       className="change-profile_edit-button"
                                       onClick={()=>changeSettingsPage("Change Password")}
                                       type="submit" >
                                       <FontAwesomeIcon
                                           style={{marginRight: 10}}
                                           icon={faPencil}/>
                                       Edit
                                   </Button>
                               </div>

                               <Form.Group controlId="customerForm.phone" className="input-container">
                                   <Form.Label className={values.phoneNumber && "filled"}>Telephone</Form.Label>
                                   <Form.Control
                                       className="form-input"
                                       type="text"
                                       placeholder=""
                                       value={values.phoneNumber}
                                       onBlur={handleBlur('phoneNumber')}
                                       onChange={ handleChange }
                                       name="phoneNumber"
                                   />
                                   <Form.Text className="text-muted">
                                       SMS messages for your alerts will be sent to this number, so please keep it up to date.
                                   </Form.Text>
                                   <DisplayErrors error={touched.phoneNumber &&  errors.phoneNumber} />
                               </Form.Group>

                               <Button type="submit" disabled={!isValid} className="gold-button-variant"> { activateForm ? 'Finish Account Setup' : 'Update Details' }</Button>
                           </Form>
                       )
                   )
               }}
           </Formik>
       )}

   </>
)};

export default CustomerInfo;


function updateCustomerDetails(customer, authService, onSuccess) {
  authService.secureAjax({
    route: '/update_customer',
    method: 'post',
    params: customer,
    onSuccess,
    onFailure(error){
      ToastNotifications.defaultErrorToast(error,-1);
      Sentry.captureException(new Error(error));
    }
  })
}

function DisplayErrors({error, field}){
  return error ? (<div style={{color: "#fa2a43", paddingBottom: "3px"}}>{error}<br /> </div>) : null
}

// const REQUIRE_PRESENCE = "Must be present";
// const PHONE_REGEX = /^(([+]\d{2}[ ]?)|0)\d{10}$/;
// const EMAIL_FORMAT_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

// function validate(values) {
//   const errors = {};
//
//   function ensurePresence(field) {
//     if (!values[field]){
//       errors[field] = REQUIRE_PRESENCE;
//     }
//   }
//
//   function validateFormat(field, regex, message, trimWhiteSpaces) {
//     if (trimWhiteSpaces) {
//       values[field] = values[field].replace(/\s+/g, '');
//     }
//     if (!regex.test(values[field]) && !errors[field]) { errors[field] = message; }
//   }
//
//   ['name', 'email', 'phoneNumber', 'emergencyName', 'emergencyPhoneNumber','emergencyContactRelationship' ].forEach(ensurePresence);
//
//   validateFormat('email',EMAIL_FORMAT_REGEX, 'Invalid email address');
//
//   validateFormat('phoneNumber', PHONE_REGEX, 'Please enter a valid number', true);
//
//   for (let prop in errors){
//     if (errors.hasOwnProperty(prop)){
//       // console.log(`${prop} has error: ${errors[prop]}`)
//     }
//   }
//
//   return errors;
// };
