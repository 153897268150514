import React, {Component} from 'react'
import axios from "axios/index";
import './user_onboarding.css'
import Spinner from '../../components/spinner/spinner'
import PasswordForm from '../../components/password_form';
import {Container} from "react-bootstrap";
import ToastNotifications from '../../components/toasty-notifications/toasty-notifications';
import * as Sentry from "@sentry/react";

class SetInitialPassword extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            loading: false,
        }
    }

    handleSubmit(currentPassword = '', password, passwordConfirmation) {
        console.log('checking params', password);
        console.log('passwordConfirmation', passwordConfirmation);
        const route = process.env.REACT_APP_API_URL + '/set_password';
        const activateAccountRoute = process.env.REACT_APP_API_URL + '/activate_account';

        this.setState({loading: true}, () => {
            axios.post(route, {
                password: password,
                passwordConfirmation: passwordConfirmation,
                initialSetup: true
            }, {withCredentials: true})
                .then((response) => {
                    return axios.post(activateAccountRoute, {
                        confirmToken: this.props.confirmToken,
                    }, {withCredentials: true});
                })
                .catch((error) => {
                    this.handleSetPasswordError(error);
                    return Promise.reject(error);
                })
                .then((response) => {
                    this.props.next();
                })
                .catch((error) => {
                    this.handleActivateAccountError(error);
                });
        });
    }

    handleSetPasswordError(error) {
        // Handle the first request error
        if (error.response && error.response.status === 400) {
            console.log(JSON.stringify(error.response.data.msg));
            this.props.setFormMessage(error.response.data.msg);
        } else {
            Sentry.captureException(new Error(error));
            ToastNotifications.defaultErrorToast(error, -1);
        }
        this.setState({loading: false});
    }

    handleActivateAccountError(error) {
        // Handle the second request error, potentially in a different way than the first request error
        Sentry.captureException(new Error(error));
        ToastNotifications.defaultErrorToast(error, -1);
        this.setState({loading: false});
    }

    render() {
        return (
            <Container>
                {this.state.loading && <Spinner message="Processing..."/>}
                <PasswordForm initialSetup={true} handleSubmit={this.handleSubmit}/>
            </Container>
        );
    }


}

export default SetInitialPassword;
