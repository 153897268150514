import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone, faUser} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const RecipientInfo = ({recipient, email, headingText}) =>  (
    <div className="header-and-recipient-info">
        <h5>{headingText}</h5>
        <hr className="separator"/>
        <div className="info-container">
            <div className="info-row">
                <div className="fa-info-icon"><FontAwesomeIcon icon={faUser}/></div>
                <div className="info-text">{recipient.name}</div>
            </div>
            <div className="info-row">
                <div className="fa-info-icon"><FontAwesomeIcon icon={faPhone}/></div>
                <div className="info-text">{recipient.recipient}</div>
            </div>
            <div className="info-row">
                <div className="fa-info-icon"><FontAwesomeIcon icon={faEnvelope}/></div>
                <div className="info-text">{email}</div>
            </div>

        </div>
    </div>
)