import React, {Component} from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import handleInputChange from '../services/handle_input_change';
import ToastNotifications from './toasty-notifications/toasty-notifications';

class PasswordForm extends Component {
  constructor(props) {
    super(props);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.state = {
      currentPassword: '',
      password: '',
      passwordConfirmation: ''
    }
  }

  validatePassword() {
    return this.state.password === this.state.passwordConfirmation;
  }

  handlePasswordSubmit(event) {
    event.preventDefault();
    if (!this.validatePassword()) {
      ToastNotifications.errorToast('','Whoops! Passwords don\'t match',5000);
    } else {
      this.props.handleSubmit(this.state.currentPassword, this.state.password, this.state.passwordConfirmation);
    }
  }

  render() {
    return (
        <>
          <Form className="container form-container" onSubmit={e => this.handlePasswordSubmit(e)}>
            {!this.props.initialSetup && (
                <Form.Group controlId="formBasicPassword" className="input-container">
                  <Form.Label className={this.state.currentPassword && "filled"}>Current Password</Form.Label>
                  <Form.Control type="password"
                                className="form-input"
                                value={this.state.currentPassword}
                                name={"currentPassword"}
                                onChange={this.handleInputChange }/>
                </Form.Group>
            )}
            <Form.Group controlId="formBasicPassword" className="input-container">
              <Form.Label className={this.state.password && "filled"}>New Password</Form.Label>
              <Form.Control type="password"
                            className="form-input"
                            value={this.state.password}
                            name={"password"}
                            onChange={this.handleInputChange }/>
            </Form.Group>

            <Form.Group controlId="formConfirmPassword" className="input-container">
              <Form.Label className={this.state.passwordConfirmation && "filled"}>Confirm Password</Form.Label>
              <Form.Control type="password"
                            className="form-input"
                            value={this.state.passwordConfirmation}
                            name={"passwordConfirmation"}
                            onChange={this.handleInputChange }/>
            </Form.Group>
            <Button className="gold-button-variant" type="submit"
                    disabled={this.state.password === '' || this.state.passwordConfirmation === ''}>
              Set password
            </Button>
          </Form>
        </>

    );
  }

}

export default PasswordForm;
