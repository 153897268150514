import React, {useEffect, useState} from 'react';
import Image from 'react-bootstrap/Image';
import ShowSubmitted from './show_submitted';
import CreateAccountFlow from './create_account_flow';
import "./signup.css"
import {SignUpForm} from "./sign_up_form";
import {ValidateActivationToken} from "./validate-activation-token";
import queryString from "query-string";

const Signup = (props) => {
    const [validateActivationToken, setValidateActivationToken] = useState(false);
    const [userEmail, setUserEmail] = useState('')
    const queryParams = queryString.parse(props.location.search);
    const needsActivating = queryParams.needsActivating;
    const emailAddress = queryParams.email

    useEffect(() => {
        if(needsActivating){
            setValidateActivationToken(true)
            setUserEmail(emailAddress)
        }
    }, [])

    const validateToken = (value) => setValidateActivationToken(value)
    const captureUserEmail = (email) => setUserEmail(email)

    return (
        <div>
            {validateActivationToken ? <ValidateActivationToken userEmail={userEmail}/> :
                <SignUpForm validateToken={validateToken} setUserEmail={captureUserEmail}/>}
        </div>
    )
};

export default Signup;




