import React, {Component} from 'react'
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import handleInputChange from '../../services/handle_input_change';
import ToastNotifications from "../../components/toasty-notifications/toasty-notifications";
import {Link} from "react-router-dom";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      email: '',
      password: '',
      rememberMe: true,
        disabledButton: false,
    };
    this.login = this.login.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
  }

  handleSubmit(event){
      if(!this.state.email || !this.state.password){
          event.preventDefault();
          this.setState({disabledButton: true})
          this.props.handleStateChange({
              name: 'formMessage',
              state: 'Email or Password invalid'
          })
      } else {
          event.preventDefault();
          this.login();
      }
  }


  login() {
    this.props.handleStateChange({
      name: 'loading',
      state: true
    });
    const {email, password, rememberMe} = this.state;
    this.props.authService.authenticate(email, password, rememberMe)
      .then(({isAuthenticated, data, error}) => {
        if (isAuthenticated) {
          this.props.handleDataOnLogin(data);

          this.props.handleStateChange({
            name: 'redirectToReferrer',
            state: true,
          });
        } else if(error.response && error.response.status === 401){
          this.props.handleStateChange({
            name: 'formMessage',
            state: 'Email or Password invalid'
          })
        } else if(error.response && error.response.status === 409){
            ToastNotifications.unverifiedEmailToast(error.response.data.error, -1,  email);
        }
      else {
          this.props.handleStateChange({
            name: 'formMessage',
            state: 'Ooops! Something went wrong...'
          });
        }
        this.props.handleStateChange({
          name: 'loading',
          state: false
        });
      })
  }

  render() {
    return (
            <div className="login-body">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%', }}>
                    <div style={{width: '100%', display: "flex", flexDirection: "column"}}
                         className="container-body-form">
                        <Form onSubmit={e => this.handleSubmit(e)} className="container login-form">
                            { this.props.formMessage ? <div style={{color: "red", fontSize: '16px'}}>{this.props.formMessage}</div> : '' }
                            <Form.Group controlId="formBasicEmail" className="input-container">
                                <Form.Label className={this.state.email && "filled"}>Email address</Form.Label>
                                <Form.Control type="email"
                                              className="form-input"
                                              value={this.state.email}
                                              name={"email"}
                                              onChange={this.handleInputChange}/>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword" className="input-container">
                                <Form.Label className={this.state.password && "filled"}>Password</Form.Label>
                                <Form.Control type="password"
                                              className="form-input"
                                              name={"password"}
                                              value={ this.state.password }
                                              onChange={this.handleInputChange}/>
                            </Form.Group>

                            <Button style={{marginTop: 15}} className="gold-button-variant" type="submit" disabled={this.state.email && this.state.password ? false : this.state.disabledButton}>
                                Sign In
                            </Button>

                            <div style={{marginTop: 20, display: "flex", flexDirection: "column", justifyContent: "center"}} className="container">
                                <Link style={{textDecoration: "none",}} to="/signup">
                                    <p style={{
                                        color: '#D7A157',
                                        fontWeight: "bolder"
                                    }}>
                                        Sign Up
                                    </p>
                                </Link>
                                <Form.Label
                                    style={{
                                        color: '#D7A157',
                                        fontWeight: "bolder"
                                    }}
                                    onClick={ () => this.props.handleStateChange({
                                            name: 'forgotPasswordForm',
                                            state: true
                                        }
                                    )}>
                                    Reset password
                                </Form.Label>
                            </div>

                        </Form>

                    </div>
                </div>
            </div>
    )
  }
}

export default LoginForm;
