import React, {Component} from 'react'
import axios from "axios/index";
import PasswordForm from '../../../components/password_form';
import ToastNotifications from '../../../components/toasty-notifications/toasty-notifications';
import * as Sentry from "@sentry/react";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      loading: false,
    }
  }

  handleSubmit(currentPassword, password, passwordConfirmation) {
    const route = process.env.REACT_APP_API_URL + '/set_password';
    return axios.post(route, {
      currentPassword: currentPassword,
        password: password,
        passwordConfirmation: passwordConfirmation
      },
      {withCredentials: true}
    ).then(function (response) {
      ToastNotifications.successToast('Password successfully updated',5000);
    }).catch(function (error) {
      Sentry.captureException(new Error(error));
      ToastNotifications.defaultErrorToast(error,-1);
    })
  }

  render() {
    return (
        <PasswordForm handleSubmit={this.handleSubmit}/>
    );
  }
}

export default ChangePassword;
