import React from 'react'
import Button from 'react-bootstrap/Button';
import './download_app.css'
import {isAndroid, isMobile} from "react-device-detect";

const DownloadApp = () => {

    const getIOSApp = () => {
        window.location = 'https://apps.apple.com/gb/app/moto-halo/id1505515077'
    }

    const getAndroidApp = () => {
        window.location = 'https://play.google.com/store/apps/details?id=com.bikeeyes'
    }

    return (
            <div className="download_page">
                    <div className="container">
                        { !isMobile ? (
                            <>
                                <Button style={{marginBottom: 10}} className="gold-button-variant" type="submit" onClick={() => getAndroidApp()} >
                                    Google Play
                                </Button>
                                <Button className="gold-button-variant download_page-button" type="submit" onClick={() => getIOSApp()} >
                                    App Store
                                </Button>
                            </>
                        ) : (
                            isAndroid ? (
                                <Button className="gold-button-variant" type="submit" onClick={() => getAndroidApp()} >
                                    Google Play
                                </Button>
                            ) : (
                                <Button className="gold-button-variant download_page-button" type="submit" onClick={() => getIOSApp()} >
                                    App Store
                                </Button>
                            )
                        )
                        }
                    </div>
            </div>
    );
}

export default DownloadApp;
