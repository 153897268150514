import React, { Component } from 'react';

import VehicleForm from './vehicle_form';
import Form from 'react-bootstrap/Form'


class EditVehicles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBikeIndex: 0
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({selectedBikeIndex: event.target.value});
    }

    render(){
        const bikes = this.props.bikes;
        const selectedBike = bikes[this.state.selectedBikeIndex];
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>
                <Form className="container form-container">
                    <Form.Group className="input-container">
                        <Form.Label className="filled">Select a bike to edit:</Form.Label>
                        <Form.Control as="select"  className="form-input select" onChange={this.handleChange} value={this.state.selectedBikeIndex}>
                            {bikes.map((bike, index) => (
                                <option style={{paddingRight: 15, marginRight: 10}} value={index} key={bike.id}>{bike.vrn}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
                <VehicleForm bike={selectedBike} authService={this.props.authService}/>
            </div>
        )
    }


}

export default EditVehicles;
