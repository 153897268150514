import React from 'react';
import UpdateEmailRequest from "./update_email_request";
import {UpdateEmailVerification} from "./updateEmailVeritification";

const ChangeEmail = ({authService, customer, getCustomer}) => {
    const {user} = customer;

    return (
    <>
        {
            user.pendingEmail && user.verificationCode ? (
                <UpdateEmailVerification
                    setCustomerData={getCustomer}
                    user={user}
                    authService={authService}
                />
            ) : (
                <UpdateEmailRequest
                    userEmail={customer.email}
                    authService={authService}
                    setCustomerData={getCustomer}
                />
            )
        }
    </>
    )
};

export default ChangeEmail;




