import React, {Component} from 'react'


class ErrorPage extends Component {

  render() {
    return (
      <div style={{color: "white", position: "absolute", top: "40%", left: "50%", transform: "translate(-50%, -50%)"}}>
        <div style={{textShadow: "1px 1px 2px #d7cbcb", fontSize: "70px"}}>404</div>
        <div style={{fontSize: "22px"}}>
          Looks like the page you were looking for is no longer here
        </div>
      </div>
    )
  }

}


export default ErrorPage