import React, {Component} from 'react'
import queryString from 'query-string';

import PasswordForm from '../../components/password_form';

import axios from "axios/index";
import ToastNotifications from "../../components/toasty-notifications/toasty-notifications";
import * as Sentry from "@sentry/react";
import Spinner from '../../components/spinner/spinner'
import {Container} from "react-bootstrap";
import Image from 'react-bootstrap/Image';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      verificationCode: '',
      loading: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(password, passwordConfirmation) {
    const route = process.env.REACT_APP_API_URL + '/set_password';
    const self = this;
    return axios.post(route, {
        password: password,
        passwordConfirmation: passwordConfirmation
      },
      {withCredentials: true}
    ).then(function (response) {
      // ToastNotifications.successToast('Password successfully updated',5000);
      self.props.authService.setAuthenticated(true);
      self.props.handleDataOnLogin();
      self.props.history.push({
        pathname: '/map',
        state: {
          notify: "Your password has been successfully updated"
        }
      })})
    .catch( (error) => {
      console.error(error);
      const { data } = error.response;
      if (data.msg){
        this.setState({formMessage: data.msg});
      } else {
        Sentry.captureException(new Error(error));
        ToastNotifications.defaultErrorToast(error,-1);
      }
    })
  }

  componentDidMount(){
    const queryParams = queryString.parse(this.props.location.search);
    console.log(JSON.stringify(queryParams));
    const verificationCode = queryParams.verification_code;
    this.setState({verificationCode},() => {
      this.processAuthentication(this.state.verificationCode)
    });
  }

  processAuthentication(token) {
    const route = process.env.REACT_APP_API_URL + '/process_authentication';
    return axios.get(route, {
      params: { confirmToken: token },
      withCredentials: true
    }).then(response => {
      this.setState({
        loading: false,
        formMessage: response.data.formMessage,
        authorised: response.data.authorised,
      });
    }).catch(function (error) {
      Sentry.captureException(new Error(error));
      console.log(error);
    })
  }

  render() {


    return (

      <Container style={{height: '100vh'}}>

        { this.state.loading && <Spinner message="Loading"/> }

        <div>
          <Image src={"/logo_transparent.png"} style={{height: '50%', width: '75%'}} className="mx-auto"/>
        </div>

        { this.state.formMessage ? <div style={{color: "#FAFAFA", paddingBottom: "3px"}}>{this.state.formMessage}<br /> </div> : '' }


        { this.state.authorised && <PasswordForm handleSubmit={this.handleSubmit}/> }

      </Container>)
  }

}

export default ForgotPassword
