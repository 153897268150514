import React, {Component} from 'react'
import './settings.css'
import SlidePair from '../../components/slider/slide_pair';
import CustomerDetails from './account_settings/customer_details';
import EditVehicles from './bike_settings/edit_vehicles';
// import AddBike from './bike_settings/add_bike';
import BackButton from '../../components/back_button';
import LogOutButton from '../login/logout_button';
import ManageBilling from './manage_billing.js';
import DownloadApp from "./download_app/download_app";
import DeleteAccount from "./delete_account/delete_account";
import EmergencyContact from "./emergency_contact/emergency_contact";
import {faDownload, faUser, faWrench, faWallet, faTrash, faSignOutAlt, faChevronRight} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Notification from 'react-notify-toast';

// TODO
//  Consider instead using nested routes, e.g. https://tylermcginnis.com/react-router-nested-routes/
//  and animate transition as e.g. https://medium.com/onfido-tech/animations-with-react-router-8e97222e25e1
// -?

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state= {
            profilePageTitle: "Profile",
        }
        this.buttonControls = this.buttonControls.bind(this);
        this.renderButtonControls = this.renderButtonControls.bind(this);
        this.currentSettingsView = this.currentSettingsView.bind(this);
        this.settingsMain = this.settingsMain.bind(this);
        this.changeTitleName = this.changeTitleName.bind(this);
        this.setDefaultTitleName = this.setDefaultTitleName.bind(this);
    }

    changeTitleName(title){
        this.setState({profilePageTitle: title})
    }

    setDefaultTitleName(){
        this.setState({profilePageTitle: "Profile"})
    }

    buttonControls(){
        return [
            {
                name: 'Download app',
                icon: faDownload,
                render: () => (<DownloadApp />)
            },
          {
              name: this.state.profilePageTitle,
              icon: faUser,
              render: () => <CustomerDetails authService={this.props.authService} changeTitleName={this.changeTitleName}/>
          },
            {
                name: 'Emergency Contact',
                icon: faUser,
                render: () => <EmergencyContact authService={this.props.authService} />},
          {
              name: 'Bike Settings',
              icon: faWrench,
              render: () => (<EditVehicles bikes={this.props.bikes}
                                           authService={this.props.authService}/>)
          },
            /*{
              name: 'Add New Bike',
              render: () => (<AddBike authService={this.props.authService}/>)
            },*/
          {
            name: 'Manage Billing',
            icon: faWallet,
            render: () => (<ManageBilling authService={this.props.authService}/>)
          },
         {
              name: 'Delete Account',
              icon: faTrash,
              //name: 'Wait just a minute',
              render: () => (<DeleteAccount authService={this.props.authService} />)
          }
        ]
    }

    settingsMain({toggleState}) {
        return (
            <div className="settings-page_container">
                <div className="settings-page_body container-settings-form">
                    <div className="settings-page_header">
                        Motohalo Settings
                    </div>
                    <div className="buttons-box button-controls" >
                        {this.renderButtonControls(toggleState)}
                    </div>
                </div>
            </div>
        );
    }


  renderButtonControls(toggleState) {
      return (
        <div className="container setting-form">
            {this.buttonControls().map((bc, index) => (
                <button style={{marginBottom: 10}}
                        className="btn btn-sm settings_page_buttons"
                        key={index}
                        onClick={()=>{ toggleState({activeComponentIndex : index})}}
                >
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", fontWeight: "400"}}>
                        <div>
                            {<FontAwesomeIcon color="#D7A157" style={{ fontSize: 17, marginLeft: 10, marginRight: 15}} icon={bc.icon}/>}
                            {bc.name}
                        </div>
                        {<FontAwesomeIcon color="#7C7A7A" style={{ fontSize: 17, textAlign: "right", marginRight: 10}} icon={faChevronRight}/>}
                    </div>
                </button>
            ))}
           <LogOutButton authService={this.props.authService} icon={faSignOutAlt}/>
        </div>
      );

  }

  specialHeaderTitle(value){
        const title = 'Wait just a minute';
        if(value === 'Delete Account'){
            return (`\uFE0F\u231b ${title}`)
        } else {return value}
  }

  currentSettingsView({toggleState, passedProps}) {
    const component = this.buttonControls()[passedProps.activeComponentIndex];
    return component ? (
        <div className="settings-page_container">
            <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", maxWidth: 470}}>
                    <Notification options={{position: "relative"}}/>
                <div className="settings-page_body container-settings-form">
                    <div className="render-container">
                        <div className="header-box">
                            <BackButton
                                onClickAction={()=>{
                                    toggleState({});
                                    this.setDefaultTitleName()
                                }}
                                buttonClass="back" />
                            <div className="settings-page_subpage-header">{this.specialHeaderTitle(component.name)}</div>
                            <div style={{width: 25}}></div>
                        </div>
                    </div>
                    <div className="render-container">
                        { component.render()}
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    ) : null;
  }

  render() {
    return (<div className="settings-page">
      <SlidePair style={{height: '100%', overflow: 'hidden'}} renderLeft={this.settingsMain}
                 renderRight={this.currentSettingsView}/>
    </div>);
  }

}

export default Settings;
