import React, {useState} from 'react'
import './delete_account.css'
import Form from 'react-bootstrap/Form';
import {Formik} from "formik";
import {SubmitButton} from "../../signup/submit-button";
import {deleteAccountValidation} from "../../../services/validation_schema"
import ToastNotifications from "../../../components/toasty-notifications/toasty-notifications";
import DeleteConfirmation from "./delete_confirmation";


const DeleteAccount = ({authService}) => {
    const [show, setShow] = useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");

    // eslint-disable-next-line consistent-return
    const deleteUserAccount = ({ email, password, deleteWord }) => {
        const DELETE_ACCOUNT_WORD = 'DELETE';
        if (deleteWord !== DELETE_ACCOUNT_WORD) {
            ToastNotifications.errorToast('','Incorrect details were entered, OK',5000);
            return null;
        }
        setPassword(password);
        setEmail(email)
        setShow(true);
    };

    return (
        <div className="delete_page">
            {show && <DeleteConfirmation  authService={authService} password={password} email={email} setShow={setShow}/>}
            <div className="delete_page-form">
                <div className="container_text-box">
                    <p>
                        Are you absolutely sure you want to delete your whole account? You will loose
                        everything: all previous ride data, all tracking history — along with any access to
                        your Motohalo devices. All subscriptions will be cancelled and everything will be
                        gone forever.
                    </p>
                    <p>
                        If you're 100% sure, confirm by logging in:
                    </p>
                </div>
                <Formik enableReinitialize
                        initialValues={{
                            email: '',
                            password: '',
                            deleteWord: '',
                        }}
                        onSubmit={deleteUserAccount}
                        validationSchema={deleteAccountValidation}
                >
                    {({handleChange, values, handleSubmit, errors, isValid, touched, handleBlur}) => (
                        <Form onSubmit={handleSubmit} className="container form-container">
                            <Form.Group className="input-container">
                                <Form.Control type="email" className="form-input"
                                              value={values.email} onChange={ handleChange } name="email" label="label"/>
                                <Form.Label className={values.email && "filled"}>Enter Email</Form.Label>
                            </Form.Group>

                            <Form.Group className="input-container">
                                <Form.Control type="text" className="form-input"
                                              value={values.password} onChange={handleChange} name="password"/>
                                <Form.Label className={values.password && "filled"}>Enter password</Form.Label>
                            </Form.Group>

                            <Form.Group className="input-container">
                                <Form.Label className={values.deleteWord && "filled"}>And typing the word <span style={{ fontWeight: 'bold' }}>DELETE</span> below</Form.Label>
                                <Form.Control type="text" className="form-input"
                                              value={values.deleteWord} onChange={handleChange} name="deleteWord"/>
                            </Form.Group>

                            <SubmitButton title="Delete Everything"/>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default DeleteAccount;

