import React, {useState} from 'react';
import "./signup.css"
import {Formik} from "formik";
import {signUpValidationSchema} from "../../services/validation_schema";
import Form from "react-bootstrap/Form";
import { submitNewCustomerDetails }  from "./sign-up-utils";
import { SubmitButton } from "./submit-button";
import Image from "react-bootstrap/Image";
import {Link} from "react-router-dom";
import Notification from 'react-notify-toast';

export const SignUpForm = ({ validateToken, setUserEmail }) => {
    const [loading, setLoading] = useState(false)
    const setLoadingValue = (value) => {
        setLoading(value)
    }
    function DisplayErrors({error, field}){
        return error ? (<div style={{color: "#fa2a43", paddingBottom: "3px", fontSize: 12, textAlign: 'center'}}>{error}<br /> </div>) : null
    }
    const handleSubmit = (values) => {
        submitNewCustomerDetails(values, validateToken, setUserEmail, setLoadingValue)
    }
    return (
        <div>
            <div className="signup">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center"}}>
                    <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", maxWidth: 470}}>
                        <Notification />
                        <div className="signup_container container-form">
                            <div className="login-form-logo-holder">
                                <Image src={"/logo_transparent.png"} style={{height: '50%', width: '75%'}} className="mx-auto"/>
                            </div>
                            <h5 style={{marginTop: 18, marginBottom: 28}}>Get a Motohalo Account</h5>
                            <div className="signup_body">
                                <Formik enableReinitialize
                                        validationSchema={signUpValidationSchema}
                                        initialValues={{name: '', phoneNumber: '', email: '', password: '' }}
                                        onSubmit={ values => {
                                            handleSubmit(values)
                                        }}>
                                    {({values, handleChange, handleSubmit, errors, touched}) => (
                                        <Form onSubmit={handleSubmit} className="container form-container">

                                           <Form.Group controlId="customerForm.name" className="input-container">
                                                <Form.Control type="text" className="form-input"
                                                              value={values.name} onChange={ handleChange } name="name"
                                                />
                                                <Form.Label className={values.name && "filled"}>Full Name</Form.Label>
                                                <DisplayErrors error={touched.name && errors.name} field="name"/>
                                            </Form.Group>

                                            <Form.Group controlId="customerForm.email" className="input-container">
                                                <Form.Control type="email" className="form-input"
                                                              value={values.email} onChange={handleChange} name="email"/>
                                                <Form.Label className={values.email && "filled"}>Email</Form.Label>
                                                <DisplayErrors error={touched.email && errors.email} field="email"/>
                                            </Form.Group>
                                            <Form.Group controlId="customerForm.phoneNumber" className="input-container">
                                                <Form.Control type="text" className="form-input"
                                                              value={values.phoneNumber} onChange={handleChange} name="phoneNumber"/>
                                                <Form.Label className={values.phoneNumber && "filled"}>Phone Number</Form.Label>
                                                <DisplayErrors error={touched.phoneNumber && errors.phoneNumber} field="phoneNumber"/>
                                            </Form.Group>
                                            <Form.Group controlId="customerForm.password" className="input-container">
                                                <Form.Control type="password" className="form-input"
                                                              value={values.password} onChange={handleChange} name="password"/>
                                                <Form.Label className={values.password && "filled"}>Password</Form.Label>
                                                <DisplayErrors error={touched.password && errors.password} field="password"/>
                                            </Form.Group>
                                            <SubmitButton loading={loading} title="Submit"/>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div style={{marginTop: 35,  color: '#fafafa'}}>
                                <p onClick={() => validateToken(true)} style={{cursor: 'pointer'}}>Already received a code? <span className="verifyCodeText"> Verify </span></p>
                            </div>
                        </div>
                    </div>


                </div>
                <div style={{marginTop: 35}}>
                    <Link style={{textDecoration: "none",}} to="/login">
                        <p style={{
                            color: '#fafafa',
                            fontWeight: "bolder"
                        }}>
                            Sign In
                        </p>
                    </Link>
                </div>
            </div>

        </div>
    )
};





