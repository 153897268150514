import React, {Component} from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import BackButton from "../../components/back_button";
import axios from "axios/index";
import { LoopingRhombusesSpinner } from 'react-epic-spinners'
import * as Sentry from "@sentry/react";
import "./reset_password.css";
import {SpinningButton} from "../signup/submit-button";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailValid: false,
      loading: false,
    };
  }


  handleSubmit(e) {
    e.preventDefault();
    this.setState({loading: true});

    const route = process.env.REACT_APP_API_URL + '/forgot_password';
    return axios.post(route, {
        email: this.state.email,
      withCredentials: true
    }).then(response => {
      this.props.handleStateChange({
        name: 'formMessage',
        state: 'We have sent a password recover instructions to your email'
      });
      this.setState({loading: false});

    }).catch(error => {
      if (error.response.data && error.response.data.msg) {
        this.props.handleStateChange({
          name: 'formMessage',
          state: error.response.data.msg
        });
      } else {
        this.props.handleStateChange({
          name: 'formMessage',
          state: 'Something went wrong'
        });
      }
      console.log(error);
      this.setState({loading: false})
      Sentry.captureException(new Error(error));
    })
  }

  handleUserInput(e) {
    const email = e.target.value;
    this.setState({ email: email },
                  () => { this.validateEmailField(email) })
  }

  handleOnBlur(){
    this.props.handleStateChange({
      name: 'formMessage',
      state: this.state.emailValid ? '' : 'Your email address is invalid.',
    });
  }

  validateEmailField(email) {
    let emailValid = this.state.emailValid;
    emailValid = !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);


    this.setState({
      emailValid: emailValid,
    });
  }

    render() {
        return (
            <div className="reset-password">
              <div className="reset-password_container container-form">
                <div className="reset-password_header">
                  <div className="reset-password_header-box">
                    <BackButton
                        onClickAction={() => this.props.handleStateChange({
                          name: 'forgotPasswordForm',
                          state: false,
                        })}
                        buttonClass="back" />
                    <div className="component-header" style={{fontWeight: "bold"}}>Reset password</div>
                    <div style={{width: 25}}></div>
                  </div>
                </div>

                <div className="reset-password_body">
                  <div className="reset-password_body-container">
                    <Form onSubmit={e => this.handleSubmit(e)} className="container reset-password-form">
                      { this.props.formMessage ? <div style={{color: "red"}}>{this.props.formMessage}</div> : '' }

                      {/*{ this.state.loading ? <div><LoopingRhombusesSpinner color="#d6a157" style={{display: "inline-flex", height: "38px", top: '8px'}}/></div> : '' }*/}

                      <Form.Text className="reset-password_form-text">
                        Enter the email associated with your account and we'll send an email with instructions to reset your password.
                      </Form.Text>

                      <Form.Group controlId="formBasicEmail" className="input-container">
                        <Form.Control type="email"
                                      className="form-input"
                                      name={"email"}
                                      value={this.state.email}
                                      onChange={(e) => this.handleUserInput(e)}
                                      onBlur={(e) => this.handleOnBlur()}
                        />
                        <Form.Label className={this.state.email && "filled"}>Enter email</Form.Label>
                      </Form.Group>

                      <div className="forgot-password-buttons-container">
                        <div style={{marginBottom: 35}}>
                          <SpinningButton action={(e) => this.handleSubmit(e)} loading={this.state.loading} title="Reset Instructions" visible={true} disabled={!this.state.emailValid || this.state.loading}/>
                        </div>
                      </div>
                    </Form>
                  </div>
              </div>
              </div>
            </div>

        )
    }
}

export default ForgotPassword
