import React, { useState } from "react";
import QrReader from "react-qr-reader";
import Button from "react-bootstrap/Button";
import {isMobile} from "react-device-detect";

const QrScanner = ({ hideScanner, setScannedIMEI, passIMEI, activateDevice }) => {
    const [result, setResult] = useState("");

    const validateIMEI = (imei) => {
       return imei.length <= 15 && /^[0-9]*$/.test(imei)
    }

    let handleScan = scannedData => {
        if (scannedData) {
            if(validateIMEI(scannedData)) {
                console.log('got validated')
                setScannedIMEI(scannedData)
                hideScanner();
                passIMEI(null, scannedData);
                activateDevice();
            } else {
                console.log('this error happened here')
                setResult('Sorry, this is not a valid IMEI')
            }
        }
    };

    const getWidth = () => isMobile ? '100%' : '100%'

    let handleError = err => {
        console.log('error occurred here')
        // alert(err);
    };
    return (
        <div style={{maxWidth: 470,  width: '100%'}}>
            <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: "100%" }}
                facingMode="environment"
            />
            <p style={{color: 'white'}}>{result}</p>
            <Button className="black-button-variant"
                    style={{display: "block", marginTop: '-3px'}}
                    onClick={() =>  hideScanner()}>
                Hide scanner
            </Button>
        </div>
    );
}

export default QrScanner