import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {faIdCardAlt} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./emergency_cotact.css";
import ToastNotifications from "../../../components/toasty-notifications/toasty-notifications";
import DeleteContactConfirmation from "./delete_contact_confirmation";
import {emergencyContactValidationSchema} from "../../../services/validation_schema"

const EmergencyContactForm = ({ authService, setEmergencyContactData, contactDetailsCreated, emergencyContact }) => {
    const [showModal, setShowModal] = useState(false);
    const [emergencySwitcherValue, setEmergencySwitcherValue] = useState(emergencyContact.enabled);

    useEffect(() => {
        setEmergencySwitcherValue(emergencyContact.enabled)
    }, [emergencyContact])

    const handleToggle = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setEmergencySwitcherValue(value)
        changeEnabled(value)
    }

    const changeEnabled = (value) => {
        const prevValue = !value;
        authService.secureAjax({
            params: {
                id: emergencyContact.id,
                enabled: value,
            },
            route: '/change_enabled',
            method: 'post',
            onSuccess() {
                setEmergencyContactData();
                ToastNotifications.successToast(`Emergency Notification has been updated`, 3000);
            },
            onFailure() {
                setEmergencySwitcherValue(prevValue);
                ToastNotifications.errorToast('', 'Sorry, something went wrong', 3000);
            }
        })
    }

    const updateEmergencyContact = (values) => {
        const { name, phoneNumber, relationship } = values;

        authService.secureAjax({
            params: {
                emergencyName: name,
                emergencyPhoneNumber: phoneNumber,
                emergencyContactRelationship: relationship,
            },
            route: '/update_emergency_contact_details_route',
            method: 'post',
            onSuccess() {
                setEmergencyContactData();
                setEmergencySwitcherValue(true);
                ToastNotifications.successToast(`Emergency Contact has been  ${contactDetailsCreated ? 'updated' : 'created'}`, 3000);
            },
            onFailure() {
                ToastNotifications.errorToast('', 'Sorry, something went wrong', 3000);
            }
        })
    }

    const deleteEmergencyContactConfirmation = () => {
        handleModal()
        authService.secureAjax({
            params: {
                id: emergencyContact.id,
            },
            route: '/destroy_emergency_contact_details',
            method: 'post',
            onSuccess() {
                setEmergencyContactData();
                ToastNotifications.successToast(`Emergency Contact has been deleted`, 3000);
            },
            onFailure() {
                ToastNotifications.errorToast('', 'Sorry, something went wrong', 3000);
            }
        })
    }

    const handleModal = () => {
        setShowModal(!showModal)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>
            {showModal && <DeleteContactConfirmation
                deleteEmergencyContactConfirmation={deleteEmergencyContactConfirmation}
                handleModal={handleModal} />}
            <Formik
                initialValues= {{
                    name: emergencyContact.name,
                    phoneNumber: emergencyContact.phoneNumber,
                    relationship: emergencyContact.relationship,
                }}
                enableReinitialize
                onSubmit={values => updateEmergencyContact(values)}
                validationSchema={emergencyContactValidationSchema}
            >
                {({handleChange, handleSubmit, handleBlur, values, isValid}) => (
                    <Form  onSubmit={handleSubmit} className="container form-container">
                        <div className="toggle-switch-box">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Notify Contact Below"
                                checked={emergencySwitcherValue}
                                onChange={(e)=>{handleToggle(e)}}
                            />
                        </div>

                        <Form.Group controlId="customerForm.name" className="input-container">
                            <Form.Label className={values.name && "filled"}>Name</Form.Label>
                            <Form.Control
                                className="form-input"
                                type="text"
                                onBlur={handleBlur('name')}
                                value={values.name}
                                onChange={ handleChange }
                                name="name"
                            />
                        </Form.Group>

                        <Form.Group controlId="customerForm.phone" className="input-container">
                            <Form.Label className={values.phoneNumber && "filled"}>Telephone number</Form.Label>
                            <Form.Control
                                className="form-input"
                                type="text"
                                value={values.phoneNumber}
                                onBlur={handleBlur('phoneNumber')}
                                onChange={ handleChange }
                                name="phoneNumber"
                            />
                        </Form.Group>

                        <div className="select-input-box" style={{}}>
                            <div style={{fontSize: 20}}><FontAwesomeIcon icon={faIdCardAlt}/></div>
                            <select className="testing"
                                    aria-label="Default select example"
                                    name="relationship"
                                    onChange={handleChange}
                                    style={{width: '100%', padding: 5, border: "none", backgroundColor: "transparent", color: "white", fontSize: 14, textTransform: 'capitalize'}}>
                                <option>{values.relationship}</option>
                                <option value="Mother">Mother</option>
                                <option value="Father">Father</option>
                                <option value="Parent">Parent</option>
                                <option value="Brother">Brother</option>
                                <option value="Sister">Sister</option>
                                <option value="Son">Son</option>
                                <option value="Daughter">Daughter</option>
                                <option value="Child">Child</option>
                                <option value="Friend">Friend</option>
                                <option value="Spouse">Spouse</option>
                                <option value="Partner">Partner</option>
                                <option value="Assistant">Assistant</option>
                                <option value="Manager">Manager</option>
                                <option value="Other">Other</option>
                                <option value="Housemate">Housemate</option>
                                <option value="Doctor">Doctor</option>
                                <option value="Emergency">Emergency</option>
                                <option value="Family Member">Family Member</option>
                                <option value="Guardian">Guardian</option>
                                <option value="Social worker">Social worker</option>
                            </select>
                        </div>

                        {isValid && (
                            <Button
                                style={{backgroundColor:'#34C759', color:"white", marginBottom: 10, marginTop: 15}}
                                type="submit"
                                disabled={!isValid}
                                className="green-button-variant"
                            >
                                Update
                            </Button>
                        )}
                    </Form>
                )}
            </Formik>

            <div className="container">
                {contactDetailsCreated && (
                    <Button onClick={()=>handleModal()} type="submit" className="red-button-variant">Delete Emergency Contact</Button>
                )}

                <p style={{textAlign: "justify", fontSize: 14, marginTop: 20}}>
                    If MotoHalo detects a serious impact our monitoring centre will be notified and you
                    will receive a call. If we are unable to reach you and the event is deemed serious we
                    will contact emergency services. In addition you can also add an emergency contact for
                    us to notify in case of emergency
                </p>
            </div>
        </div>
    )};

export default EmergencyContactForm;
