import React, {Component} from 'react';
import './App.css';

import {BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";
import Notification from 'react-notify-toast';
// import {Provider, KeepAlive} from 'react-keep-alive';

import Settings from './views/settings/settings';
import Login from './views/login/login';
import ProtectedRoute from "./services/protected_route";
import AuthService from './services/auth_service';
import UserOnboarding from "./views/login/user_onboarding";
import ResetPassword from "./views/login/reset_password"
import Signup from './views/signup/signup'

import ToastNotifications from './components/toasty-notifications/toasty-notifications';
import NetworkDetector from "./components/network_detector";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "./services/error_boundary";
import LandscapeBlocker from "./components/landscape_blocker/landscape_blocker";
import ErrorPage from "./components/error_page";

import { DateTime } from 'luxon';
import ManageBilling from "./views/settings/manage_billing";
import Spinner from "./components/spinner/spinner";
// import {ErrorBoundary} from "@sentry/react";


class App extends Component {
  constructor(props) {
    super(props);

    const now = DateTime.local();

    this.authService = new AuthService(this);
    this.state = {
      isAuthenticated: JSON.parse(localStorage.getItem('isAuthenticated')), // gets initial value from local storage
      timezoneInfo: {},
      bikes: [],
      selectedBike: null,
      landscapeOrientation: false,
      loading: false,
      accountStatusChecked: false,
      // i originally wanted to have something like:
      // currentTime: {
      //   hour: now,
      //   minute: now,
      //   second: now,
      // }
      // but wasn't sure how to update (if possible at all) nested properties
      // inside the state in React
      currentTimeH: now,
      currentTimeM: now,
      currentTimeS: now,
    };
    this.setBikeData = this.setBikeData.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.getBikeData = this.getBikeData.bind(this);
    this.setCurrentTimeIntervals();
  };

  setBikeData(data) {
    const selectedBikeId = (this.state.selectedBike && this.state.selectedBike.id) || data.bikes[0].id;
    const selectedBike = data.bikes.find(bike => bike.id === selectedBikeId);
    const initialData = Object.assign(data, {selectedBike});
    this.setState(initialData);
  }

  getBikeData() {
    let app = this;
    return this.authService.secureAjax({
      route: '/data',
      method: 'get',
      onSuccess(response) {
        app.setBikeData(response.data);
      },
      onFailure(error) {
        ToastNotifications.defaultErrorToast(error, -1);
        Sentry.captureException(new Error(error));
      }
    })
  }

  setCurrentTimeIntervals = () => {
    const minute = 60000;
    const hour = 60 * minute;

    setInterval(
      () => this.setState({ currentTimeH: DateTime.local() }),
      hour
    );

    setInterval(
      () => this.setState({ currentTimeM: DateTime.local() }),
      minute
    );


    // because we currently have a problem that child components
    // rerender without a reason if anything inside the state of App.js changes,
    // i had to temporarily disable this timer as we don't want our
    // child coponents to rerender every second:

    // setInterval(
    //   () => this.setState({ currentTimeS: new Date() }),
    //   1000
    // );
  };

  setupBikeData = () => {
    return this.getBikeData()
      .then(() =>{
        setInterval(
          () => {
            this.getBikeData() //TODO simplify
          },
          60 * 1000
        );
      })
  };

  checkAccountStatus = () => {
    const app = this;
    app.setState({accountStatusChecked: false})
    this.authService.secureAjax({
      route: '/account_status',
      method: 'get',
      onSuccess(response) {
        const { status } = response.data;
        if (status === "OK"){
          app.setState({accountStatusChecked: true})
          console.log(app.state)
          console.log('status ok')
          app.setupBikeData();
        } else if(status === 'payment_required'){
          if(! (window.location.href.match(/activate_account/) || window.location.href.match(/manage_billing/))) {
            app.authService.sendToBilling();
          }
        } else if (status === 'activation_incomplete'){
          console.log('got caught here')
          if(! (window.location.href.match(/activate_account/) || window.location.href.match(/manage_billing/))){
            window.location = `activate_account/?confirm_token=${response.data.activationToken}`;
          }
        }
      },

      onFailure(error) {
        app.setState({accountStatusChecked: true})
        ToastNotifications.defaultErrorToast(error, -1);
        Sentry.captureException(new Error(error));
      }
    })
  };

  blockLandscapeOrientation = () =>  {
    if (window.orientation === 90 || window.orientation === -90) {
      this.setState({landscapeOrientation: true});
    }
    window.addEventListener('orientationchange', () => {
      if (window.orientation === 90 || window.orientation === -90) {
        this.setState({landscapeOrientation: true});
      } else {
        this.setState({landscapeOrientation: false});
      }
    });
  };

  processLinkRedirect = () => {
    const { href } = window.location;
    const match = href.match(/^https?:\/\/[^/]+\/link\/(.*)$/);
    if (match) {
      const link = match[1];
      if (link && link.length > 0) {
        window.location = `https://live.motohalo.co.uk/link/${link}`;
        return true;
      }
    }
    return false;
  };

  componentDidMount() {
    this.blockLandscapeOrientation();
    if (this.state.isAuthenticated) {
      this.checkAccountStatus();
    }
  }

  render() {
    if (this.processLinkRedirect()) {
      return null;
    }

    const isAuthenticated = this.state.isAuthenticated;
    return (

      <div className="App">

        <Router>

          <div id="content">
            <Switch>

            <ProtectedRoute path="/settings" isAuthenticated={isAuthenticated} render = {
                routeProps => (<Settings {...routeProps}
                                         bikes={this.state.bikes}
                                         authService={this.authService}
                                         statusChecked={this.state.statusChecked}
                />)
            }/>

            <ProtectedRoute path="/manage_billing" isAuthenticated={isAuthenticated} render = {
              routeProps => (<ManageBilling {...routeProps}
                                       bikes={this.state.bikes}
                                       authService={this.authService}/>)
            }/>

            <Route path="/login" render={
              routeProps => (
                <ErrorBoundary>
                  <Login {...routeProps} authService={this.authService}
                         handleDataOnLogin={this.checkAccountStatus}/>
                </ErrorBoundary>
              )
            }/>


            <Route path="/activate_account" render={
              routeProps => (
                <ErrorBoundary>
                  <UserOnboarding {...routeProps} authService={this.authService}
                                  handleDataOnLogin={this.setupBikeData}/>
                </ErrorBoundary>
              )
            }/>

            <Route path="/reset_password_confirmation" render={
              routeProps => (
                <ErrorBoundary>
                  <ResetPassword {...routeProps} authService={this.authService}
                                   handleDataOnLogin={this.checkAccountStatus}/>
                </ErrorBoundary>
              )
            }/>
              <Route path="/signup/:needsActivating?/:email?" render={(props) => <Signup {...props} />}/>

            <Route exact path="/" render={(props) => {
              if(isAuthenticated) {
                if(this.state.accountStatusChecked) {
                  return (<Redirect to="/settings"/>)
                }
              } else {
                return (<Redirect to="/login"/>)
              }
            }}/>

            {/*<Route path="*">*/}
            {/*  <ErrorPage />*/}
            {/*</Route>*/}

            </Switch>
          </div>
          <NetworkDetector/>
          <div style={{position: "absolute", top: "2%", width: "100%", display: "flex", justifyContent: "center", paddingLeft: 10, paddingRight: 10}}>
            <div className="notification-container">
              <Notification />
            </div>
          </div>

        </Router>
        <LandscapeBlocker
          showModal={this.state.landscapeOrientation}
        />
      </div>
    );

  }
}

export default App;


//test build
