import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import ErrorBoundary from "./error_boundary";

// based on https://tylermcginnis.com/react-router-protected-routes-authentication/
const ProtectedRoute = ({component: Component, render, isAuthenticated, ...rest}) => {
  const toRender = render ? render : (props) => (<ErrorBoundary><Component {...props}/></ErrorBoundary>);
  return <Route {...rest} render={(props) => (
    isAuthenticated ? (<ErrorBoundary>{toRender(props)}</ErrorBoundary>) :
      <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
  )}/>
};

export default ProtectedRoute;