import React, {Component} from 'react'
import {Formik} from 'formik';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ToastNotifications from '../../../components/toasty-notifications/toasty-notifications';

class VehicleForm extends Component {

    handleVehicleSubmit(vehicleData) {
        const route = '/manage_bike';
        const method = 'post';
        const params = vehicleData;
        const verb = vehicleData.id === -1 ? 'add' : 'edit';
        const onSuccess = () => { ToastNotifications.successToast(`Vehicle ${verb}ed successfully`, 5000) };
        const onFailure = () => { ToastNotifications.errorToast ('',`Sorry, something went wrong while ${verb}ing bike`, 5000);
        };

        this.props.authService.secureAjax({
            route,
            method,
            params,
            onSuccess,
            onFailure
        });
    }

    presentFloat(float) {
        return Math.round(float).toLocaleString();
    }

    render() {
        const {id, make, model, vrn, imei, summary } = this.props.bike;
        const odometerMiles = this.presentFloat(summary.odometerMiles);
        const { addNewBike } = this.props.bike
        return (
            <Formik
                initialValues={{id, make, model, vrn, imei, odometerMiles }}
                onSubmit={ values => {
                        this.handleVehicleSubmit(values);
                    }
                }
                enableReinitialize
                render={({values, handleChange, handleSubmit}) => (
                    <Form onSubmit={handleSubmit} className="container form-container" style={{textAlign: "left"}}>
                        <Form.Group className="input-container">
                            <Form.Label className={values.vrn && "filled"}>Registration Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="vrn"
                                value={values.vrn}
                                onChange={handleChange}
                                className="form-input"
                            />
                        </Form.Group>

                        <Form.Group className="input-container">
                            <Form.Label className={values.imei && "filled"}>Device IMEI</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly={!addNewBike}
                                name="imei"
                                value={values.imei}
                                onChange={handleChange}
                                className="form-input"
                            />
                        </Form.Group>

                        <Form.Group className="input-container">
                            <Form.Label className={values.make && "filled"}>Manufacturer</Form.Label>
                            <Form.Control
                                type="text"
                                name="make"
                                value={values.make}
                                onChange={handleChange}
                                className="form-input"
                            />
                        </Form.Group>

                        <Form.Group className="input-container">
                            <Form.Label className={values.model && "filled"}>Model</Form.Label>
                            <Form.Control
                                type="text"
                                name="model"
                                value={values.model}
                                onChange={handleChange}
                                className="form-input"
                            />
                        </Form.Group>

                        <Form.Group className="input-container">
                            <Form.Label className={values.odometerMiles && "filled"}>Odometer</Form.Label>
                            <Form.Control
                                type="number"
                                name="odometerMiles"
                                value={values.odometerMiles}
                                onChange={handleChange}
                                className="form-input"
                            />
                        </Form.Group>

                        <Button type="submit" className="gold-button-variant" style={{marginTop: 15}}>Save</Button>
                    </Form>
                )
                }
            />
        )
    }

}

export default VehicleForm;
