import React from 'react';
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ToastNotifications from "../../../../components/toasty-notifications/toasty-notifications";
import {emailValidationSchema} from "../../../../services/validation_schema";

const UpdateEmailRequest = ({userEmail, authService, setCustomerData}) => {

    const updateUserEmail = (inputData) => {
        const { email, currentPassword } = inputData;
        authService.secureAjax({
            params: {email, currentPassword },
            route: '/process_email_update',
            method: 'post',
            onSuccess() {
                setCustomerData()
            },
            onFailure(error) {
                let errorMsg = 'Error occurred while sending data';
                console.log(error.response.status)
                if (error.response.data.errors.length > 0) {
                    errorMsg = error.response.data.errors[0];
                }
                ToastNotifications.errorToast('', errorMsg, 3000);
            }
        })
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    email: userEmail,
                    currentPassword: '',
                }}
                onSubmit={updateUserEmail}
                validationSchema={emailValidationSchema}
            >
                {({ handleChange, handleBlur, values, handleSubmit, errors, isValid}) => (
                    <Form onSubmit={handleSubmit} className="container form-container">
                        <Form.Group className="input-container">
                            <Form.Label className={values.email && "filled"}>New Email</Form.Label>
                            <Form.Control type="email"
                                          className="form-input"
                                          value={values.email}
                                          name="email"
                                          onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="input-container">
                            <Form.Label className={values.currentPassword && "filled"}>Current Password</Form.Label>
                            <Form.Control type="password"
                                          className="form-input"
                                          name="currentPassword"
                                          value={values.currentPassword}
                                          onChange={handleChange}
                            />
                        </Form.Group>
                        <p>Your current password is required to update or change the account email address</p>
                        <Button className="gold-button-variant" type="submit" disabled={!isValid}>
                            Update Details
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    )
};

export default UpdateEmailRequest;
