import React, {Component} from 'react'
import './user_onboarding.css'
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Spinner from '../../components/spinner/spinner'
import axios from "axios/index";
import queryString from 'query-string';
import SetInitialPassword from './set_initial_password';
import { SuccessScreen } from "./success_screen";
import SetupBilling from './setup_billing';
//TODO reinstate?
// import ActivateDevicePage from './activate_device_page/activate_device_page';

import * as Sentry from "@sentry/react";
import ToastNotifications from "../../components/toasty-notifications/toasty-notifications";
import {MoveDevicesToAccount} from "./move_devices_to_account";


class UserOnboarding extends Component {
  constructor(props) {
    super(props);

    // Activating an account is a multi-step process
    // Each step in the process is a component shown here, in order


    this.components = {
      setPassword: SetInitialPassword,
      moveDevicesToAccount: MoveDevicesToAccount,
      setupBilling: SetupBilling,
      accountActivated: SuccessScreen
    };

    const self = this;
    this.propsFor = {
      setPassword() {
        return {
          authService: self.props.authService,
          email: self.state.email,
          confirmToken: self.state.token.confirm_token,
          addedDevices: self.state.token["addedDevices[]"],
          next: self.next,
          setFormMessage: (msg) => {
            self.setState({formMessage: msg})
          }
        }
      },
      moveDevicesToAccount() {
        return {
          authService: self.props.authService,
          email: self.state.email,
          confirmToken: self.state.token.confirm_token,
          recipient: self.state.recipient,
          billingUser: self.state.billingUser,
          next: self.next,
          setFormMessage: (msg) => {
            self.setState({formMessage: msg})
          }
        }
      },
      setupBilling(){
        return {
          email: self.state.email,
          recipient: self.state.recipient,
          authService: self.props.authService,
          pendingActivationDevices: self.props.pendingActivationDevices,
          // onSuccess: self.completeActivationProcess,
          next: () => { self.next() }
        }
      },
      accountActivated() {
        return {
          authService: self.props.authService,
        }
      }
    };

    this.state = {
      activeStepIndex: 0,
      activeProps: {},
      loading: true,
      formMessage: '',
      authorised: true,
      token: null,
      email: '',
      activationSteps: [],
      recipient: {},
      billingUser: true,
    };

    this.next = this.next.bind(this);
    this.processAuthentication = this.processAuthentication.bind(this);
  }

  componentDidMount(){
    this.setState({token: queryString.parse(this.props.location.search)}, () => {
      this.processAuthentication(this.state.token);
    });
  }

  // This function advances you to the next step in the process, passing that component activeProps
  next(formMessage = ""){


    this.setState(state => {
      return {
        activeStepIndex: state.activeStepIndex + 1,
        formMessage
      }
    });
  }

  // Having this as a normal method like all the others
  // failed with undefined this, even with the right
  // binding statement in the constructor. wtf?
  completeActivationProcess = () => {
    const onSuccess = () => {
      this.props.handleDataOnLogin()
        .then(()=> {
          this.props.history.push({
            pathname: '/map',
            state: {
              notify: "Your account has been successfully activated!"
            }
          })
        });
    };

    const onFailure = (error) => {
      ToastNotifications.defaultErrorToast(error, -1);
      Sentry.captureException(new Error(error));
    };

    this.props.authService.secureAjax({
      route: '/complete_account_activation',
      method: 'post',
      onSuccess,
      onFailure
    })
  };

  processAuthentication(queryParams) {
    const route = process.env.REACT_APP_API_URL + '/process_authentication';
    const params = {
      confirmToken: queryParams.confirm_token
    };
    return axios.get(route, {
      params,
      withCredentials: true
    }).then(response => {
      this.signInForActivation(response)
    }).catch((error) => {
      Sentry.captureException(new Error(error));
      console.log(error);

      const formMessage = 'Sorry, an error has occurred.';
      this.setState({
        loading: false,
        formMessage
      })
    })
  }

  signInForActivation(response) {
    const {email, formMessage, authorised, activationSteps, recipient, billingUser} = response.data;
    if (authorised) {
      this.props.authService.setAuthenticated(true);
    }
    this.setState({
      loading: false,
      formMessage,
      authorised,
      email,
      activationSteps,
      recipient,
      billingUser
    });
  }
  render() {
    if(this.state.loading){
      return (
        <Container style={{height: '100vh'}}>
          <Spinner message="Loading"/>
        </Container>
      )
    }
    console.log('is billing', this.state.billingUser)
    console.log(`in login procedures, activeIndex: ${this.state.activeStepIndex}, activationSteps: ${this.state.activationSteps}, componentName: ${this.state.activationSteps[this.state.activeStepIndex]}`)
    const stepName = this.state.activationSteps[this.state.activeStepIndex];
    const Active = this.components[stepName];
    console.log(this.propsFor)
    const activeProps = this.propsFor[stepName]();
    return (

      <Container className="userOnBoarding_container">
        <div className="userOnBoarding_body-container">
          <div className="userOnBoarding_logo-container">
            <div style={{maxWidth: 470}}>
              <Image src={"/logo_transparent.png"} style={{ padding: 10, width: '85%'}} className="mx-auto"/>
            </div>
          </div>

          { this.state.formMessage ? <div style={{color: "#FAFAFA", paddingBottom: "3px"}}>{this.state.formMessage}<br /> </div> : '' }

          { this.state.authorised && <Active {...activeProps} /> }

        </div>

      </Container>)
  }

}

export default UserOnboarding;
